import React from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';

const Welcome = () => {
    return (
        <>
            <Container className='mt-5'>
                <h1 className='text-center'>Bienvenido a los servicios de Negrdo!</h1>
                <p className="text-center">Tu socio confiable en logística y distribución. Optimiza tu cadena de suministro con nosotros. ¡Descubre nuestras soluciones hoy mismo!</p>
                <p className="text-center">
                    Estamos listos para ser tu socio en el éxito de tu negocio. Explora nuestros servicios y descubre cómo podemos ayudarte a optimizar tu cadena de suministro y llevar tu negocio al siguiente nivel.
                    <br />
                    ¡Gracias por considerar a Negrdo como tu elección para la administración de tu negocio confiable y de confianza!
                </p>
                <Row xs={1} md={2} className="g-4">
                    {/* {Array.from({ length: 3 }).map((_, idx) => ( */}
                    <Col>
                        <Card className="text-center">
                            <Card.Header>
                                <div style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                    NEGRDO DISTRIBUCIONES
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Card.Title>PREMIUM</Card.Title>
                                <Card.Text className='text-center'>
                                    <h2 className="text-success" style={{ fontSize: '40px', fontWeight: 'bold' }}>$15.000 /MES</h2>
                                    Este servicio incluye las siguientes funciones:
                                    <br />
                                    Rutas y clientes
                                    <br />
                                    Proveedores y productos
                                    <br />
                                    Vendedores, Secretarios, Repartidores y Empacadores
                                    <br />
                                    Ventas y facturación
                                    <br />
                                    Usuarios
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer className="text-muted">
                                <a href="https://dist.negrdo.com/register" target="_blank" className="btn btn-outline-success">Obtener una cuenta</a>
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="text-center">
                            <Card.Header>
                                <div style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                    NEGRDO VENTAS
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Card.Title>PREMIUM</Card.Title>
                                <Card.Text className='text-center'>
                                    <h2 className="text-success" style={{ fontSize: '40px', fontWeight: 'bold' }}>$10.000 /MES</h2>
                                    Este servicio incluye las siguientes funciones:
                                    <br />
                                    Clientes
                                    <br />
                                    Proveedores y productos
                                    <br />
                                    Ventas y facturación
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer className="text-muted">
                                <a href="https://sales.negrdo.com/register" target="_blank" className="btn btn-outline-success">Obtener una cuenta</a>
                            </Card.Footer>
                        </Card>
                    </Col>
                    {/* <Col>
                            <Card className="text-center">
                                <Card.Header>Premium</Card.Header>
                                <Card.Body>
                                    <Card.Title>Special title treatment</Card.Title>
                                    <Card.Text>
                                        With supporting text below as a natural lead-in to additional content.
                                    </Card.Text>
                                    <Button variant="success">Obtener una cuenta</Button>
                                </Card.Body>
                                <Card.Footer className="text-muted">2 days ago</Card.Footer>
                            </Card>
                        </Col> */}
                    {/* ))} */}
                </Row>
            </Container>
        </>
    );
}
export default Welcome;