import React, { useEffect, useState } from 'react';
import Welcome from './components/public/welcome';
import Layout from './components/public/layout';
import { Route, Routes } from 'react-router-dom';
import useScripts from './scripts/custom-script';
import Services from './components/public/services';

const RouteLinks = () => {

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 1000);
  }, []);

  //useScripts("/js/jquery.min.js");

  // if (!loaded)
  //   return (
  //     <div style={{ display: 'flex', justifyContent: 'center', minHeight: '800px', alignItems: 'center' }}>
  //       <h2 style={{ textAlign: 'center' }}>
  //         <div className="spinner-border text-success" role='status'></div>{" "}
  //         Loading...
  //       </h2>
  //     </div>
  //   );
  // else
    return (
      <Routes>
        <Route path="/" element={<Layout />} >
          <Route index element={<Welcome />} />
          <Route path="services" element={<Services />} />
        </Route>
        {/* <Route path="*" element={<Notfound />} /> */}
      </Routes>
    );

}

function App() {
  return (
    <>
      <RouteLinks />
    </>
  );
}

export default App;
