import React from "react";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";

const Footer = () => (
    <div className="container mt-5">
        <hr />
        <div className="row">
            <div className="col-md-12 d-flex justify-content-center">
                <div className="col-lg-3 col-md-6 text-center col-12">
                    <h4 className="my-4">Informacion de contacto</h4>
                    <p className="mb-1">
                        <i className="fa fa-phone mr-2 footer-icon"></i>
                        WhatsApp +57 301 782 8735
                    </p>

                    <p>
                        <i className="fa fa-envelope mr-2 footer-icon"></i>
                        proyecto.negrdo@gmail.com
                    </p>
                </div>

                <div className="col-lg-3 col-md-6 text-center col-12">
                    <h4 className="my-4">Nuestra ubicación</h4>

                    <p className="mb-1">
                        <i className="fa fa-home mr-2 footer-icon"></i>
                        Calle 1 CARRERA 4-158 PUERTO VIEJO - TOLU, Colombia
                    </p>
                </div>
            </div>
        </div>
        <div className="row mt-5">
            <div className="col-lg-4 mx-lg-auto text-center col-md-8 col-12">
                <p className="copyright-text">
                    Copyright &copy; Negrdo
                </p>
            </div>

        </div>
    </div >
);

// const Header = () => (
//     <Navbar collapseOnSelect expand="lg" data-bs-theme="light" bg="white" className="bg-body-tertiary">
//         <Container>
//             <Navbar.Brand href="#home">
//                 <img
//                     alt=""
//                     src="/images/logoNegrdo.png"
//                     width="40"
//                     height="40"
//                     className="d-inline-block align-top"
//                 />
//             </Navbar.Brand>
//             <Navbar.Toggle aria-controls="responsive-navbar-nav" />
//             <Navbar.Collapse id="responsive-navbar-nav">
//                 <Nav className="me-auto">
//                     <Nav.Link href="#pricing">Acerca de</Nav.Link>
//                     <NavDropdown title="Dropdown" id="collapsible-nav-dropdown">
//                         <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
//                         <NavDropdown.Item href="#action/3.2">
//                             Another action
//                         </NavDropdown.Item>
//                         <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
//                         <NavDropdown.Divider />
//                         <NavDropdown.Item href="#action/3.4">
//                             Separated link
//                         </NavDropdown.Item>
//                     </NavDropdown>
//                 </Nav>
//                 <Nav>
//                     <Nav.Link href="#deets">Services</Nav.Link>
//                     <Nav.Link href="#memes">Contactos</Nav.Link>
//                 </Nav>
//             </Navbar.Collapse>
//         </Container>
//     </Navbar>
// );


const Layout = () => {

    return (
        <>
            <main>
                <Outlet />
            </main>
            <Footer />
        </>
    );
}
export default Layout;